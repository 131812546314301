<script setup>
    const props = defineProps({
        dataObject: {
            type: Object,
        },
        showAccordion: {
            type: Boolean,
            default: false
        }
    })
</script>

<template>
    <template v-if="showAccordion">
        <ORowContainer style="max-height: 58px;" class="border-bottom">
            <div class="accordion" id="navigateAcordeonTop">
                <div class="accordion-item border-0">
                    <div id="navigateAcordeon" class="accordion-collapse collapse position-absolute w-100 me-3" style="z-index: 1000;" aria-labelledby="navigateAcordeonHeader">
                        <div class="accordion-body p-0">
                            <slot/>
                        </div>
                    </div>
                </div>
            </div>
        </ORowContainer>
    </template>
    <template v-else>
    <ORowContainer class="h-100">
        <slot/>
    </ORowContainer>
    </template>
</template>

<style scoped>
    .accordion-button:focus {
        outline: none !important;
        box-shadow: none !important;
    }
</style>